<template>
  <div>
    <p class="title">
      <el-link type="primary" style="fontSize:18px" :underline="false" @click="goback">账单下载</el-link>>><span>日账单</span>
    </p>
    <div class="agent_search">
      <label class="type_lab" for="search">业务单号</label><el-input v-model="order_no" name="search" placeholder="请输入业务单号"
        class="goods_search"></el-input>
      <el-button type="primary" style="marginLeft:5px" @click="getdays(2, 1)">搜索</el-button>
      <el-button type="primary" @click="reset">重置</el-button>
      <el-button type="primary" @click="getdays(1)">导出</el-button>
    </div>
    <div style="textAlign:left;padding:95px 0 5px 10px;fontSize:16px">收入：<span style="color:blue;paddingRight:20px">{{
      count.income_num }}笔，共{{ count.income_price }}元</span>支出：<span style="color:red">{{ count.expenditure_num }}笔，共{{
    count.expenditure_price }}元</span></div>
    <div style="width:99%; margin:0 auto">
      <el-table :data="tableData" height="600" border style="width: 100%">
        <el-table-column prop="close_time" label="时间">
        </el-table-column>
        <el-table-column prop="good_name" label="商品说明" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="order_no" label="业务单号" width="290">
        </el-table-column>
        <el-table-column prop="type_str" label="业务类型" width="120px">
        </el-table-column>
        <el-table-column label="收支金额" width="180">
          <template slot-scope="scope">
            <span :style="{ 'color': scope.row.sz == '收入' ? 'blue' : 'red' }">{{ scope.row.money }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button type="text" @click="look(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination style="margin-top:5px;textAlign:center" background prev-text='上一页' next-text='下一页'
        layout="total , prev, pager, next, jumper" @current-change="current_change" :total="total" :page-size="pagesize"
        :current-page.sync="currentPage">
      </el-pagination>
    </div>
    <el-dialog title="详情" center :visible.sync="detail" width="700px">
      <el-row>
        <el-col :span="12">商品说明</el-col>
        <el-col :span="12">{{ value.remark }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="12">记账类型</el-col>
        <el-col :span="12">{{ value.accounting_type }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="12">业务单号</el-col>
        <el-col :span="12">{{ value.order_no }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="12">时间</el-col>
        <el-col :span="12">{{ value.create_time }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="12">业务类型</el-col>
        <el-col :span="12">{{ value.type }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="12">关联商户号</el-col>
        <el-col :span="12">{{ value.m_account }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="12">充值账号</el-col>
        <el-col :span="12">{{ value.recharge_account }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="12">充值状态</el-col>
        <el-col :span="12">{{ value.state }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="12">充值数量</el-col>
        <el-col :span="12">{{ value.num }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="12">收支金额</el-col>
        <el-col :span="12">{{ value.money }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="12">商品归属</el-col>
        <el-col :span="12">{{ value.supplier_str }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="12">商户交易变更前金额（元）</el-col>
        <el-col :span="12">{{ value.before_money }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="12">商户交易变更后金额（元）</el-col>
        <el-col :span="12">{{ value.after_money }}</el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      count: "",
      value: "",
      tableData: [],
      order_no: "",
      currentPage: 1,
      pagesize: 10,
      total: 0,
      detail: false,
    }
  },
  mounted() {
    if (this.$route.params.time == undefined) {
      this.goback()
    }
    this.getdays(2)
  },
  methods: {
    goback() {
      this.$router.push({ path: "/homes/billdown" })
    },
    reset() {
      this.order_no = ''
      this.getdays(2,1)
    },
    current_change(page) {
      this.currentPage = page;
      this.getdays(2)
    },
    look(row) {
      this.$axios.post('/supplier/billing/info', { id: row.id }).then((res) => {
        if (res.data.code == 20000) {
          this.value = res.data.data
          this.detail = true
        } else {
          this.$message({
            type: "warning",
            message: res.data.message
          })
        }
      })
    },
    getdays(exp, page) {
      if (page == 1) {
        this.currentPage = 1
      }
      this.$axios.post('/supplier/billing/day.order', { datetime: this.$route.params.time, order_no: this.order_no, export: exp, page: this.currentPage, limit: this.pagesize }).then((res) => {
        if (res.data.code == 20000) {
          if (exp == 1) {
            window.open(res.data.data, "_blank")
          } else {
            this.tableData = res.data.data.list.rows
            this.total = res.data.data.list.total
            this.count = res.data.data.count
          }
        } else {
          this.tableData = []
          this.total = 0
          this.count = ''
        }

      })
    }
  },
}
</script>

<style scoped>
.title {
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
  border-bottom: 1px solid gray;
  text-align: left;
  font-size: 16px;
}

.agent_search {
  width: 99%;
  height: 80px;
  border: 1px solid #ccc;
  margin-top: 10px;
  float: left;
  text-align: left;
  margin-left: 10px;
}

.type_lab {
  height: 34px;
  width: 90px;
  display: inline-block;
  line-height: 80px;
  text-align: center;
  font-size: 14px;
}

.goods_search {
  width: 400px;
  height: 30px;
  text-align: left
}

.el-row {
  border-bottom: 1px solid black;
  height: 30px;
  padding-top: 10px;
  font-size: 15px;
}
</style>